import React from 'react';
import {    DropInMenuContainer, Icon, CloseIcon, DropInMenuItems,
            DropInMenuWrapper, DropInMenuLink} from './DropInMenuStyling';

const DropInMenu = (props) => {
    return (
        <>
            <DropInMenuContainer isOpen={props.isOpen} onClick={props.toggle}>
                <Icon onClick={props.toggle}>
                    <CloseIcon></CloseIcon>
                </Icon>    
                <DropInMenuWrapper>
                    <DropInMenuItems>
                        <DropInMenuLink to="/" onClick={props.toggle} >Home</DropInMenuLink>
                        <DropInMenuLink to="/it" onClick={props.toggle} >IT Solutions</DropInMenuLink>
                        <DropInMenuLink to="/data" onClick={props.toggle} >Data Science</DropInMenuLink>
                        <DropInMenuLink to="/location" onClick={props.toggle} >Locationbased Services</DropInMenuLink>
                        <DropInMenuLink to="/contact" onClick={props.toggle} >Kontakt</DropInMenuLink>
                    </DropInMenuItems>
                </DropInMenuWrapper>
            </DropInMenuContainer>
        </>
    )
};

export default DropInMenu;
