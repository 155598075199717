import styled from "styled-components";

export const ContactContainer = styled.div`
    height: 100vh;
    width: 100%;
    background-color: #070B11;
    position: relative;
`;

export const TextContainer = styled.div`
    margin: auto auto;
    width: 80%;
    max-width: 800px;
    padding-top: 100px;
`;

export const ImageContainer = styled.div`
    margin: 40px auto;
    width: 80%;
    max-width: 600px;
`;

export const ContactImage = styled.img`
    display: block;
    width: 100%;
`;

export const PageHeadline = styled.div`
    color: #D09573;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 30px;

    @media screen and (orientation:landscape) {
        font-size: 2rem;
    }
`;

export const TextHeadBody = styled.div`
    color: white;
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.5;

    @media screen and (orientation:landscape) {
        font-size: 1.5rem;
    }
`;

export const DetailedTextBody = styled.div`
    color: white;
    font-size: 1rem;
    text-align: left;
    line-height: 1.5;

    @media screen and (orientation:landscape) {
        font-size: 1.2rem;
    }
`;