import React from 'react';
import { FooterContainer, FooterElement } from './FooterStyling';

function Footer() {
    return (
        <FooterContainer>
            <FooterElement to='/#home'>Home</FooterElement>
            <FooterElement to='/impressum'>Impressum</FooterElement>
            <FooterElement to='/datenschutz'>Datenschutz</FooterElement>
            <FooterElement to='/contact'>Kontakt</FooterElement>
        </FooterContainer>
    )
}

export default Footer;
