import React, { useState, useEffect } from 'react';
import Footer from '../footer/Footer';
import DropInMenu from '../menu/DropInMenu';
import Navbar from '../navbar/Navbar';
import ContactImg from '../../images/contact-fryce.jpg';
import { ContactContainer, TextContainer, PageHeadline, 
             DetailedTextBody, ImageContainer, ContactImage } from './ContactStyling';

const Contact = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <DropInMenu isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} useScrollEffect='false' externalPage='true' />
            <ContactContainer>
                <TextContainer>
                    <PageHeadline>Kontakt</PageHeadline>
                    <DetailedTextBody>
                        Konnten wir ihr Interesse wecken?<br />
                        Haben Sie Frage, Kommentare oder Anregungen?<br />
                        Kontaktieren Sie uns gerne jederzeit.
                    </DetailedTextBody>
                </TextContainer>
                <ImageContainer>
                    <ContactImage src={ContactImg} />
                </ImageContainer>
            </ContactContainer>
            <Footer />
        </>
  );
};

export default Contact;