import styled from "styled-components";
import {MdArrowDownward, MdKeyboardArrowDown} from 'react-icons/md';

export const LandingContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.8) 100%), 
                    linear-gradient(180deg, rgba(0,0,0,0.4) 0%, transparent);
        z-index: 2;
    }
`;

export const LandingBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const BgImg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const LandingContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FryceLogo = styled.img`
    margin: 20px 20px;
    max-width: 200px;
    max-height: 200px;
`;

export const FryceSlogan = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 32px;
    text-align: center;
    max-width: 600px;
    padding: 10px 10px;
    //background-color: rgba(0, 0, 0, .5);

    @media screen and (max-width: 768px) {
        font-size: 32px;
    }

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`;

export const LandingBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowDownward = styled(MdArrowDownward)`
    font-size: 20px;
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
    font-size: 20px;
`;