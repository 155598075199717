import React, {useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import fryceLogo from '../../images/frycelogowhite.png';
import {    Nav, NavbarContainer, NavLogo,
            NavLogoLink, RNavLogoLink, MobileIcon } from './NavbarElemts';

const Navbar = (props) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        if(props.useScrollEffect === 'false') {
            setScrollNav(true);
        } else {
            window.addEventListener('scroll', changeNav);
        }
        return () => {
            window.removeEventListener('scroll', changeNav);
        }
    }, [props.useScrollEffect]);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <IconContext.Provider value={{ color: '#fff'}}>
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        {
                            props.externalPage === 'true' ?
                                <RNavLogoLink to='/' >
                                    <NavLogo src={fryceLogo} scrollNav={scrollNav} />
                                </RNavLogoLink>
                            :
                                <NavLogoLink to='/' onClick={toggleHome}>
                                    <NavLogo src={fryceLogo} scrollNav={scrollNav} />
                                </NavLogoLink>
                        }
                        <MobileIcon onClick={props.toggle}>
                            <FaBars />
                        </MobileIcon>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;
