import styled from "styled-components";
import { HashLink as Link } from 'react-router-hash-link';

export const FooterContainer = styled.div`
    background-color: #2b2423;
    padding-top: 25px;
    padding-bottom: 25px;
`;

export const FooterElement = styled(Link)`
    margin: 25px;
    color: darkgray;
    font-size: 0.8rem;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: lightgray;
    }

    @media screen and (max-width: 450px) {
        margin: 25px 15px;
    }
`;