import styled from "styled-components";

export const ImpressumContainer = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: #070B11;
    position: relative;
`;

export const TextContainer = styled.div`
    margin: auto auto;
    width: 80%;
    max-width: 800px;
    padding-top: 100px;
    padding-bottom: 100px;
`;

export const PageHeadline = styled.div`
    color: #D09573;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 30px;

    @media screen and (orientation:landscape) {
        font-size: 2rem;
    }
`;

export const TextHeadBody = styled.div`
    color: white;
    font-size: 1.2rem;
    margin-top: 20px;
    line-height: 1.5;

    @media screen and (orientation:landscape) {
        font-size: 1.5rem;
    }
`;

export const DetailedTextBody = styled.div`
    color: white;
    font-size: 1rem;
    text-align: left;
    line-height: 1.5;
    margin-top: 10px;

    @media screen and (orientation:landscape) {
        font-size: 1.2rem;
    }
`;