import styled from "styled-components";

export const DetailedTextBody = styled.div`
    font-size: 1rem;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 30px;

    @media screen and (orientation:landscape) {
        font-size: 1.2rem;
    }
`;

export const DetailsExplanation = styled.div`
    color: white;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    max-width: 800px;
    align-items: center;
    text-align: center;

    @media screen and (orientation:landscape) {
        flex-basis: 100%;
        margin-top: 130px;
    }
`;

export const DetailsList = styled.ul`
    margin-left: 50px;
`;

export const DetailsExplanationContainer = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
`;