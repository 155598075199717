import React, { useState, useEffect } from 'react';
import { locationBasedServices } from '../../Content';
import DropInMenu from '../menu/DropInMenu';
import Navbar from '../navbar/Navbar';
import { RButton } from '../ButtonElement';
import { TeaserImage, TextHeadline, WhatWeDoContainer, WhatWeDoCore,
          ArrowBack, ArrowLeft, BtnWrapper } from '../whatwedo/WhatWeDoStyling';
import { DetailsExplanationContainer, DetailsExplanation,
          DetailedTextBody, DetailsList } from './DetailsStyling';
import Footer from '../footer/Footer';

const LocationServices = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen);
  }

  const [hover, setHover] = useState(false);
    
  const onHover = () => {
      setHover(!hover);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DropInMenu isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} useScrollEffect='false' externalPage='true' />
      <WhatWeDoContainer>
        <WhatWeDoCore>
            <TeaserImage TeaserImg={locationBasedServices.TeaserImg} />
            <DetailsExplanationContainer>
                <DetailsExplanation>
                    <TextHeadline>{locationBasedServices.HeadlineTextDE}</TextHeadline>
                    <DetailedTextBody>
                      Durch jahrelange Berufserfahrung im Bereich Location Based Services schlägt unser Herz für die Mobilität von Morgen. Durch die Mitwirkung in diversen Forschungsprojekten zum Thema Mobilität haben wir in den vergangen Jahren unser Expertenwissen in diesem Bereich an vorderster Front der Entwicklung moderner Mobilitätslösungen eingebracht.
                    </DetailedTextBody>
                    <DetailedTextBody>
                      Zu unseren Kompetenzen in diesem Bereich zählen unter anderem:
                      <DetailsList>
                        <li>Digital Maps: OSM, HERE, TomTom ..</li>
                        <li>Referenzierungen: OpenLR, TMC, ..</li>
                        <li>Map-Visualization: Leaflet, HTML/JS, ..</li>
                        <li>Routing: Dijkstra/A*, Contraction Hierarchies, ..</li>
                        <li>Traffic state estimation: Modellbasierte Ansätze</li>
                        <li>Protokolle: Datex, TPEG TEC/TFP, TMEX ..</li>
                        <li>Sensoren: Fahrzeugdaten, Schleifendaten, ..</li>
                        <li>Planung: Verkehrs- und Umlegungsmodelle, LSA Steuerung, Richtlinien, ..</li>
                        <li>Verkehrssimulationen: PTV Vissim, Aimsun, ..</li>
                        <li>Mobilität: Carsharing, on-demand mobility, ..</li>
                      </DetailsList>
                    </DetailedTextBody>
                </DetailsExplanation>
                <BtnWrapper>
                  <RButton to='/#locations'
                          onMouseEnter={onHover}
                          onMouseLeave={onHover}
                          primary='true'
                          dark='true'
                          >
                      {hover ? <ArrowBack /> : <ArrowLeft />}
                  </RButton>
                </BtnWrapper>
            </DetailsExplanationContainer>
        </WhatWeDoCore>
      </WhatWeDoContainer>
      <Footer />
    </>
  );
};

export default LocationServices;