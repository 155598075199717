import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';

export const Nav = styled.nav`
    background: ${({ scrollNav }) => (scrollNav ? '#000' : 'transparent' )};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    //transition: 0.8s all ease;
`;

export const NavbarContainer = styled.div`
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px 0 24px;
`;

export const NavLogoLink = styled(LinkS)`
    cursor: pointer;
    text-decoration: none;
`;

export const RNavLogoLink = styled(LinkR)`
    cursor: pointer;
    text-decoration: none;
`;

export const NavLogo = styled.img`
    display: ${({ scrollNav }) => (scrollNav ? 'block' : 'none' )};
    position: absolute;
    left: 12x;
    top: 10px;
    height: 60px;
`;

export const MobileIcon = styled.div`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
`;