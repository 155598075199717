import React, { useState } from 'react';
import LandingPage from './landingpage/LandingPage';
import Navbar from './navbar/Navbar';
import DropInMenu from './menu/DropInMenu';
import Footer from './footer/Footer';
import WhatWeDo from './whatwedo/WhatWeDo';
import { dataAndAlgorithms, softwareDesign, 
    locationBasedServices } from '../Content';


const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <DropInMenu isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <LandingPage />
            <WhatWeDo {...softwareDesign} ImgPos='left' id='software' nextSection='algos'/>
            <WhatWeDo {...dataAndAlgorithms} ImgPos='right' id='algos' nextSection='locations'/>
            <WhatWeDo {...locationBasedServices} ImgPos='left' id='locations' nextSection='home'/>
            <Footer />
        </>
    )
}

export default Home;
