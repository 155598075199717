import React, { useState, useEffect } from 'react';
import { dataAndAlgorithms } from '../../Content';
import DropInMenu from '../menu/DropInMenu';
import Navbar from '../navbar/Navbar';
import { RButton } from '../ButtonElement';
import { TeaserImage, TextHeadline, WhatWeDoContainer, WhatWeDoCore,
          ArrowBack, ArrowLeft, BtnWrapper } from '../whatwedo/WhatWeDoStyling';
import { DetailsExplanationContainer, DetailsExplanation,
          DetailedTextBody, DetailsList } from './DetailsStyling';
import Footer from '../footer/Footer';

const DataScience = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen);
  }

  const [hover, setHover] = useState(false);
    
  const onHover = () => {
      setHover(!hover);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DropInMenu isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} useScrollEffect='false' externalPage='true' />
      <WhatWeDoContainer>
        <WhatWeDoCore>
            <TeaserImage TeaserImg={dataAndAlgorithms.TeaserImg} />
            <DetailsExplanationContainer>
                <DetailsExplanation>
                    <TextHeadline>{dataAndAlgorithms.HeadlineTextDE}</TextHeadline>
                    <DetailedTextBody>
                      Durch die Entwicklung vieler, neuer Big Data Frameworks hat sich die Anwendung von Big Data Methodiken in letzter Zeit deutlich vereinfacht, wodurch wiederum möglichen Einsatzgebiete wachsen. Klassische Anwendungsbereiche wie Rohdatenauswertung oder Bildverarbeitung werden heutzutage ergänzt um Analysen zu Kundenverhalten oder Prozessoptimierungen.
                      Auch die Verwendung von KI ist heutzutage nicht mehr nur Bestandteil von intelligenten Robotern oder Haushaltsgeräten, sondern findet Einsatz in nahezu jeglichem Umfeld - von der Steuerung von Marketing Kampagnen bis hin zu Lieferkettenüberwachung.
                    </DetailedTextBody>
                    <DetailedTextBody>
                      Durch diese breiten Anwedungsmöglichkeiten wächst auch die Tool-Landschaft stetig. Wir setzten dabei nicht nur auf die Verwendung von Buzz-Words oder neuesten Tools, sondern auf das Verständnis der zugrundeliegenden Technologien. Nur so ist ein optimaler Einsatz von Big Data Technologien oder KI Algorithmen möglich.
                    </DetailedTextBody>
                    <DetailedTextBody>
                      Zu unseren Kompetenzen in diesem Bereich zählen unter anderem:
                      <DetailsList>
                        <li>Frameworks: Tensorflow, Keras, ..</li>
                        <li>Machine learning: (Deep) Neural Networks, ..</li>
                        <li>Tooling: Jupyter Notebook, Matlab, Cloud-Dienste ..</li>
                      </DetailsList>
                    </DetailedTextBody>
                </DetailsExplanation>
                <BtnWrapper>
                  <RButton to='/#algos'
                          onMouseEnter={onHover}
                          onMouseLeave={onHover}
                          primary='true'
                          dark='true'
                          >
                      {hover ? <ArrowBack /> : <ArrowLeft />}
                  </RButton>
                </BtnWrapper>
            </DetailsExplanationContainer>
        </WhatWeDoCore>
      </WhatWeDoContainer>
      <Footer />
    </>
  );
};

export default DataScience;
