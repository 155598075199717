export const dataAndAlgorithms  = {
    TeaserImg: require('./images/globe.png'),
    linkedPage: 'data',
    HeadlineTextEN: 'Big Data and AI',
    BodyTextEN: 'Handling and analysing Big Data is an ease today. Learn more about our capabilities and where to apply AI.',
    HeadlineTextDE: 'Big Data und KI',
    BodyTextDE: 'Auch große Datenmengen lassen sich heutzutage effizient verarbeiten und analysieren. Erfahren Sie mehr über moderne Datenanalysen und wo es sinnvoll ist KI einzusetzen.'
};

export const softwareDesign = {
    TeaserImg: require('./images/it.png'),
    linkedPage: 'it',
    HeadlineTextEN: 'From rapid prototypes to a solid product',
    BodyTextEN: 'Applying modern cloud solutions to working prototypes is only a sweep away. With the right architecture you can easily scale out. We show you how.',
    HeadlineTextDE: 'Vom ersten Prototypen bis zum stabilen Endprodukt',
    BodyTextDE: 'Dank moderner Cloud-Dienste können Prototypen im Handumdrehen erstellt und nahtlos zu professionellen Anwendungen ausgebaut werden. Wir unterstüzen Sie bei der Entwicklung von Softwaresystemen, auf die Sie sich verlassen können.'
};

export const locationBasedServices = {
    TeaserImg: require('./images/traf.png'),
    linkedPage: 'location',
    HeadlineTextEN: 'Location based services are all around us',
    BodyTextEN: 'We have profound knowledge in the field of location-based services. From working with digital maps and geo referenceing to routing algorithms and traffic predictions. Let"s get in touch.',
    HeadlineTextDE: 'Location-Based Services sind aus unserer heutigen Welt nicht mehr wegzudenken',
    BodyTextDE: 'Vom Transformieren verschiedener Geo-Referenzierungen, über Anwendungen mit digitalen Karten bis zu individuellen Routingverfahren und Verkehrsprognosen: Mit unserer Erfahrung wird Ihr Produkt zum Erfolg.'
};
