import React, {useState} from 'react';
import { RButton, Button } from '../ButtonElement';
import { Explanation, TeaserImage, TextBody, ExplanationContainer,
            BtnWrapper, TextHeadline, WhatWeDoContainer, WhatWeDoCore,
            ArrowForward, ArrowRight,  ArrowDownward, ArrowDown,
            ArrowUpward, ArrowUp } from './WhatWeDoStyling';

function WhatWeDo({TeaserImg, HeadlineTextDE, BodyTextDE, linkedPage, ImgPos, id, nextSection}) {

    const [hoverR, setHoverR] = useState(false);
    const [hoverD, setHoverD] = useState(false);

    const onHoverR = () => {
        setHoverR(!hoverR);
    }

    const onHoverD = () => {
        setHoverD(!hoverD);
    }

    return (
        <WhatWeDoContainer>
            <WhatWeDoCore>
                <TeaserImage TeaserImg={TeaserImg} pos={ImgPos} id={id}/>
                <ExplanationContainer>
                    <Explanation>
                        <TextHeadline>{HeadlineTextDE}</TextHeadline>
                        <TextBody>{BodyTextDE}</TextBody>
                    </Explanation>
                    <BtnWrapper>
                        <Button to={nextSection} 
                                onMouseEnter={onHoverD}
                                onMouseLeave={onHoverD}
                                primary='true'
                                dark='true'
                                smooth='true'
                                >
                            {   
                                nextSection === 'home' ?
                                
                                    hoverD ? <ArrowUpward /> : <ArrowUp />
                                :
                                    hoverD ? <ArrowDownward /> : <ArrowDown />
                            }
                        </Button>
                        <RButton to={linkedPage} 
                                onMouseEnter={onHoverR}
                                onMouseLeave={onHoverR}
                                primary='true'
                                dark='true'
                                >
                            {hoverR ? <ArrowForward /> : <ArrowRight />}
                        </RButton>
                    </BtnWrapper>
                </ExplanationContainer>
            </WhatWeDoCore>
        </WhatWeDoContainer>
    )
}

export default WhatWeDo;
