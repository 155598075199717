import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DataScience from './components/details/DataScience';
import ITSolutions from './components/details/ITSolutions';
import LocationServices from './components/details/LocationServices';
import Impressum from './components/impressum/Impressum';
import Contact from './components/contact/Contact';
import Home from './components/Home';
import Datenschutz from './components/impressum/Datenschutz';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/it' element={<ITSolutions />} exact />
        <Route path='/location' element={<LocationServices />} exact />
        <Route path='/data' element={<DataScience />} exact />
        <Route path='/impressum' element={<Impressum />} exact />
        <Route path='/contact' element={<Contact />} exact />
        <Route path='/datenschutz' element={<Datenschutz />} exact />
      </Routes>
    </Router>
  );
}

export default App;
