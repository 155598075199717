import React, { useState, useEffect} from 'react';
import { softwareDesign } from '../../Content';
import DropInMenu from '../menu/DropInMenu';
import Navbar from '../navbar/Navbar';
import { RButton } from '../ButtonElement';
import { TeaserImage, TextHeadline, WhatWeDoContainer, WhatWeDoCore,
          ArrowBack, ArrowLeft, BtnWrapper } from '../whatwedo/WhatWeDoStyling';
import { DetailsExplanationContainer, DetailsExplanation,
          DetailedTextBody, DetailsList } from './DetailsStyling';
import Footer from '../footer/Footer';

const ITSolutions = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen);
  }

  const [hover, setHover] = useState(false);
    
  const onHover = () => {
      setHover(!hover);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DropInMenu isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} useScrollEffect='false' externalPage='true' />
      <WhatWeDoContainer>
        <WhatWeDoCore>
            <TeaserImage TeaserImg={softwareDesign.TeaserImg} />
            <DetailsExplanationContainer>
                <DetailsExplanation>
                    <TextHeadline>{softwareDesign.HeadlineTextDE}</TextHeadline>
                    <DetailedTextBody>
                      Durch die Verwendung von Cloud-Diensten kann bei der Entwicklung eines Prototypen der Fokus stark auf die inhaltlichen Aspekte gelegt werden. So ist es möglich in wenigen Stunden oder Tagen erste erlebbare Demos zu entwickeln.
                      Wird bei der Implementierung auf die richtige Software-Architektur geachtet, so kann nahtlos skaliert werden.
                    </DetailedTextBody>
                    <DetailedTextBody>
                      Wir setzten bei der Softwareentwicklung auf exzessive Nutzung von Automatisierung u. a. durch Nutzung von Infrastructure as code. Begonnen bei einem ersten Prototypen geschieht die Weiterentwicklung in kleinen, inkrementellen Schritten. Auch größere Systeme sollten durch eine Gliederung in Sub-Module stets überschaubar und wartbar bleiben.
                      Unsere breite Kompetenz auf dem Gebiet Softwareentwicklung - von Infrastruktur über Backend bis Frontend - ermöglicht uns ganzheitliche Lösungen in unterschiedlichen Reifegraden anzubieten, egal ob Sie schnell einen Prototypen benötigen oder ein Produkt in Endkundenreife.
                    </DetailedTextBody>
                    <DetailedTextBody>
                      Zu unseren Kompetenzen zählen unter anderem:
                      <DetailsList>
                        <li>Gängige Programmiersprachen u. a. JAVA, Python, Go, ..</li>
                        <li>Kommunikation: HTTP/REST, GraphQL, MQTT, ..</li>
                        <li>Hosting: AWS, GCP, Azure, private cloud, ..</li>
                        <li>Datenbanken: Postgres, Oracle, NoSQL, ..</li>
                        <li>In-memory DB: Hazelcast, Redis, ..</li>
                        <li>Alerting / Monitoring: Prometheus, Grafana, ..</li>
                        <li>Front-end: Javascript, React, React Native, Dart and Flutter, ..</li>
                        <li>Containerized: Kubernetes, Docker, ..</li>
                        <li>Methodik: Agile (Scrum/Kanban), DevOps, ..</li>
                      </DetailsList>
                    </DetailedTextBody>
                </DetailsExplanation>
                <BtnWrapper>
                  <RButton to='/#software'
                          onMouseEnter={onHover}
                          onMouseLeave={onHover}
                          primary='true'
                          dark='true'
                          >
                      {hover ? <ArrowBack /> : <ArrowLeft />}
                  </RButton>
                </BtnWrapper>
            </DetailsExplanationContainer>
        </WhatWeDoCore>
      </WhatWeDoContainer>
      <Footer />
    </>
  );
};

export default ITSolutions;