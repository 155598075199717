import React, {useState} from 'react';
import BackImage from '../../images/bg2.jpg';
import logoImg from '../../images/frycelogowhite.png';
import {    LandingContainer, LandingBg, BgImg,
            LandingContent, FryceLogo, FryceSlogan, LandingBtnWrapper, ArrowDownward, ArrowDown }
            from './LandingPageStyling';
import { Button } from '../ButtonElement';

const LandingPage = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <LandingContainer id='home'>
            <LandingBg>
                <BgImg src={BackImage} />
            </LandingBg>
            <LandingContent>
                <FryceLogo src={logoImg} />
                <FryceSlogan>
                    Moderne IT-Lösungen für <br />eine innovative Zukunft
                </FryceSlogan>
                <LandingBtnWrapper>
                    <Button to='software' 
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary='true'
                            dark='true'
                            smooth='true'
                            >
                        {hover ? <ArrowDownward /> : <ArrowDown />}
                    </Button>
                </LandingBtnWrapper>
            </LandingContent>
        </LandingContainer>
    )
}

export default LandingPage;
